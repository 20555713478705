import {
  createStore
} from "vuex"

import {
  UPDATE_FILTERS,
} from './actions'

const store = createStore({
  state: {
    filters: {
      startDate: "",
      endDate: ""
    }
  },

  mutations: {
    [UPDATE_FILTERS](state, payload) {
      state.filters = payload.filters
    }
  },

  actions: {
    async [UPDATE_FILTERS](context, payload) {
      context.commit(UPDATE_FILTERS, payload)
    },
  },

  getters: {
    startDate(state) {
      return state.filters.startDate;
    },

    endDate(state) {
      return state.filters.endDate;
    },
  },
})

export default store
