import './assets/main.css';
import App from './App.vue'
import {
  createApp
} from 'vue'
const app = createApp(App);

// Router
import router from './router'
app.use(router)

// Store
import store from './store'
app.use(store);


// DayJS
import dayjs from 'dayjs'
app.config.globalProperties.$dayjs = dayjs

app.mount('#app')
