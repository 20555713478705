<template>
  <div id="App">
    <!-- <div class="container"> -->
      <Header/>
      <main>
        <router-view></router-view>
      </main>
    <!-- </div> -->
  </div>
</template>

<script>
import Header from './components/Header.vue'
export default {
  name: "App",
  title: "Game Forecast Prototype",
  components:{
    Header
  },
  created() {
    document.title = this.$options.title;
  },
};
</script>
<style scoped>
  main{
    padding-top: 60px;
  }
</style>