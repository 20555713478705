import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import GameList from '../views/GameList.vue'

const routes = [{
    path: '/',
    name: 'home',
    component: GameList
  },
  {
    path: '/:key/details',
    name: 'details',
    component: () => import('../views/Details.vue')
  },
  {
    path: '/player-forecast/:player',
    name: 'playerForecasts',
    component: () => import('../views/PlayerForecast.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
